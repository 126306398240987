<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="shower"
        scrollable
        persistent
        max-width="400px"
        v-if="resolutionScreen >= 500"
      >
        <!-- <template v-slot:activator="{ on }">
        <v-btn color="primary" dark v-on="on">Open Dialog</v-btn>
      </template>-->
        <v-card>
          <v-card-title
            >{{ $t("sentFileOnechat.header") }}<v-spacer></v-spacer>
            <v-icon :color="color.theme" dense dark size="35">mdi-chat</v-icon>
          </v-card-title>
          <v-divider></v-divider>
          <v-tabs :color="color.theme">
            <v-tab :color="color.theme">{{
              $t("sentFileOnechat.friend")
            }}</v-tab>
            <!-- <v-tab>อีเมล</v-tab> -->
          </v-tabs>
          <v-card-text style="height: 350px;">
            <br />
            <div v-if="havefriend === true">
              <div v-if="loadingfriends === true" class="text-center">
                <v-progress-circular
                  :size="50"
                  :color="color.theme"
                  indeterminate
                ></v-progress-circular>
              </div>
              <div v-else>
                <v-list nav dense shaped>
                  <!-- {{checkfriend}} -->
                  <v-list-item-group :color="color.theme">
                    <v-list-item v-for="item in listfriend" :key="item.one_id">
                      <template>
                        <v-list-item-icon
                          v-if="
                            item.display_pic === null || item.display_pic === ''
                          "
                        >
                          <v-icon large>account_circle</v-icon>
                        </v-list-item-icon>
                        <v-list-item-avatar v-else>
                          <img :src="item.display_pic" alt="John" />
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            item.display_name
                          }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-checkbox
                            v-model="checkfriend"
                            :value="item"
                            :color="color.theme"
                          ></v-checkbox>
                        </v-list-item-action>
                      </template>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </div>
            </div>
            <div v-else class="text-center">
              <br />
              <br />
              <br />
              <v-icon size="100">mdi-emoticon-sad-outline</v-icon>
              <br />
              <br />
              <h2 style="font-weight: 300">
                {{ $t("sentFileOnechat.nofriend") }}
              </h2>
            </div>
            <br />
          </v-card-text>
          <v-card-text>
            <v-chip
              class="ma-2"
              :color="color.theme"
              :text-color="color.chipText"
              @click:close="close"
            >
              <v-avatar left>
                <v-icon>{{ filedata.file_icon[0] }}</v-icon>
              </v-avatar>
              {{ filedata.file_name }}
            </v-chip>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              outlined
              @click="$emit('closesendonechat'), cleardata(), $emit('openDrag')"
              :disabled="createprogress"
              >{{ $t("sentFileOnechat.close") }}</v-btn
            >
            <v-btn
              :color="color.theme"
              :dark="color.darkTheme"
              @click="summitsendonechat(), $emit('openDrag')"
              :loading="createprogress"
              >{{ $t("sentFileOnechat.choose") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="shower"
        scrollable
        persistent
        :max-width="maxWidthOnMobile"
        content-class="rounded-lg"
        v-else
      >
        <v-card>
          <v-card-text class="pa-5">
            <v-layout class="pt-2 pb-4">
              <v-flex d-flex justify-center class="ml-8">
                <span :style="headerPage">{{
                  $t("sentFileOnechat.header")
                }}</span>
              </v-flex>
              <v-flex xs1 d-flex justify-end>
                <v-icon
                  :color="$vuetify.theme.dark ? 'white' : color.theme"
                  @click="
                    $emit('closesendonechat'), cleardata(), $emit('openDrag')
                  "
                  >mdi-close</v-icon
                >
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-text style="height: 400px;">
            <v-tabs :color="color.theme">
              <v-tab :color="color.theme">{{
                $t("sentFileOnechat.friend")
              }}</v-tab>
              <!-- <v-tab>อีเมล</v-tab> -->
            </v-tabs>
            <div v-if="havefriend === true">
              <div v-if="loadingfriends === true" class="text-center">
                <v-progress-circular
                  :size="50"
                  :color="color.theme"
                  indeterminate
                ></v-progress-circular>
              </div>
              <div v-else>
                <v-list nav dense shaped>
                  <!-- {{checkfriend}} -->
                  <v-list-item-group :color="color.theme">
                    <v-list-item v-for="item in listfriend" :key="item.one_id">
                      <template>
                        <v-list-item-icon
                          v-if="
                            item.display_pic === null || item.display_pic === ''
                          "
                        >
                          <v-icon large>account_circle</v-icon>
                        </v-list-item-icon>
                        <v-list-item-avatar v-else>
                          <img :src="item.display_pic" alt="John" />
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            item.display_name
                          }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-checkbox
                            v-model="checkfriend"
                            :value="item"
                            :color="color.theme"
                          ></v-checkbox>
                        </v-list-item-action>
                      </template>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </div>
            </div>
            <div v-else class="text-center">
              <br />
              <br />
              <br />
              <v-icon size="100">mdi-emoticon-sad-outline</v-icon>
              <br />
              <br />
              <h2 style="font-weight: 300">
                {{ $t("sentFileOnechat.nofriend") }}
              </h2>
            </div>
          </v-card-text>
          <v-card-text>
            <v-chip
              class="ma-2"
              :color="color.theme"
              :text-color="color.chipText"
              @click:close="close"
            >
              <v-avatar left>
                <v-icon>{{ filedata.file_icon[0] }}</v-icon>
              </v-avatar>
              {{ filedata.file_name }}
            </v-chip>
          </v-card-text>
          <div class="text-center my-4">
            <v-btn
              :style="btnAction"
              class="mr-6"
              height="40px"
              width="40%"
              color="red"
              outlined
              @click="$emit('closesendonechat'), cleardata(), $emit('openDrag')"
              :disabled="createprogress"
              >{{ $t("sentFileOnechat.close") }}</v-btn
            >
            <v-btn
              height="40px"
              width="40%"
              class="elevation-0"
              :style="btnAction"
              :color="color.theme"
              :dark="color.darkTheme"
              @click="summitsendonechat(), $emit('openDrag')"
              :loading="createprogress"
              >{{ $t("sentFileOnechat.choose") }}</v-btn
            >
          </div>
        </v-card>
      </v-dialog>
    </v-row>
    <checksendtoonechatmulti
      :show="opemdialogchecksendtoonechatmulti"
      :filedata="filedata"
      :checkfriend="checkfriend"
      @closedialogreload="
        (opemdialogchecksendtoonechatmulti = false),
          cleardata(),
          $emit('closedialog')
      "
      @countuser="clear_data()"
      @closedialog="closereload(), (loadingfriends = true)"
    ></checksendtoonechatmulti>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";
import gbfGenerate from "@/globalFunctions/generateAuthorize";

const checksendtoonechatmulti = () =>
  import("../optional/dialog-checksendtoonechatmulti");

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  components: { checksendtoonechatmulti },
  props: ["show", "parentfolder", "filedata"],
  data: function() {
    return {
      opemdialogchecksendtoonechatmulti: false,
      checkloopclosedialog: false,
      checkfriend: [],
      choosefriend: [],
      listfriend: [],
      filename: "",
      receiver: "",
      subjectmail: "",
      createprogress: false,
      content: "",
      havefriend: true,
      loadingfriends: true,
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    shower: {
      get() {
        if (this.show === true) {
          // let filename = this.filedata.file_name.split(".");
          // console.log(filename);
          // let i;
          // let namewithoutextension = "";
          // for (i = 0; i < filename.length; i++) {
          //   if (i !== filename.length - 1) {
          //     namewithoutextension += filename[i];
          //   } else if (filename.length - 1 === 0) {
          //     namewithoutextension += filename[i];
          //   }
          // }
          // this.filename = namewithoutextension;
          // if (this.filedata.file_type === "folder") {
          //   Toast.fire({
          //     icon: "warning",
          //     title: this.$t('sentFileOnechat.cannotsendfolder')
          //   });
          //   this.$emit("closesendonechat");
          // } else {
          //   this.listfriend = [];
          //   this.loadfriend();
          // }
          // this.$emit('closeDrag')
        }
        return this.show;
      },
      set(value) {
        if (!value) {
          this.$emit("closesendonechat");
        }
      },
    },
    // style อักษร header ของ mobile
    headerPage() {
      return (
        "color:" +
        this.color.theme +
        ";" +
        "font-size: 16px; font-weight: 600; font-weight: bold; letter-spacing: 0px;"
      );
    },
    // style ปุ่ม ของ mobile
    btnAction() {
      return "font-size: 16px; font-weight: lighter;";
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    // ความกว้างของ dialog ของ ipad และ mobile
    maxWidthOnMobile() {
      if (this.resolutionScreen >= 400) {
        return 600;
      } else {
        return 346;
      }
    },
  },
  watch: {
    show: function(val) {
      if (val === true) {
        let filename = this.filedata.file_name.split(".");
        console.log(filename);
        let i;
        let namewithoutextension = "";
        for (i = 0; i < filename.length; i++) {
          if (i !== filename.length - 1) {
            namewithoutextension += filename[i];
          } else if (filename.length - 1 === 0) {
            namewithoutextension += filename[i];
          }
        }
        this.filename = namewithoutextension;

        if (this.filedata.file_type === "folder") {
          Toast.fire({
            icon: "warning",
            title: this.$t("sentFileOnechat.cannotsendfolder"),
          });
          this.$emit("closesendonechat");
        } else {
          this.listfriend = [];
          this.loadfriend();
        }
        this.$emit("closeDrag");
      } else {
        this.$emit("openDrag");
      }
    },
  },
  methods: {
    closereload() {
      this.opemdialogchecksendtoonechatmulti = false;
      this.listfriend = [];
      this.loadfriend();
    },
    cleardata() {
      this.foldername = "";
      this.createprogress = false;
      this.choosefriend = "";
      this.checkfriend = [];
      this.listfriend = [];
      (this.havefriend = true), (this.loadingfriends = true);
      //this.loadfriend();
    },
    close() {
      alert("Chip close clicked");
    },
    async loadfriend() {
      this.listfriend = [];
      let payload = {
        account_id: this.dataAccountId,
        //user_id: this.dataUsername
      };
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_ONECHAT + "/api/getlistfriend_onechat",
          payload,
          { headers: { Authorization: auth.code } }
        )
        .then((response) => {
          if (response.data.status === "OK") {
            for (
              let i = 0;
              i < response.data.result["list_friend"].length;
              i++
            ) {
              if (response.data.result["list_friend"][i].type !== "bot") {
                this.listfriend.push(response.data.result["list_friend"][i]);
              }
            }
            // this.listfriend = response.data.result["list_friend"];
            this.havefriend = true;
            this.loadingfriends = false;
          } else {
            this.loadingfriends = false;
            this.havefriend = false;
          }
        });
    },
    async summitsendonechat() {
      if (this.checkfriend.length == 0) {
        Toast.fire({
          icon: "error",
          title: this.$t("sentFileOnechat.addreceiver"),
        });
      } else {
        this.opemdialogchecksendtoonechatmulti = true;
      }
      //อันใหม่
      // console.log("filedata",this.filedata);
      // console.log("checkfriend",this.checkfriend);

      // for (let i = 0; i < this.checkfriend.length; i++) {
      //   // let objIndex = this.checkfriend.findIndex((obj) => obj.one_id === this.listfriend[i].one_id);

      //     // this.checkfriend[objIndex]["file_status"] = this.$t('dialogmultidelete.deleting');
      //     let payload = {
      //       account_id: this.dataAccountId,
      //       receiver_id: this.checkfriend[i].one_id,
      //       file_id: this.filedata.file_id
      //     };
      //     console.log(payload);
      //     await this.fn_senonechat(payload, this.checkfriend[i].one_id,this.checkfriend[i]).then(async (res) => {
      //       //  if(res.status === "sendtoonechat success"){
      //       //  }else{
      //       //  }
      //     });
      //   if(this.checkfriend.length - i === 1){
      //     // this.checkloopclosedialog = true;
      //     // this.check_dis_button = false;
      //       // this.$emit("closesendonechat");
      //       // this.$emit("updatedatafile");
      //       // this.cleardata();
      //   }
      //   console.log("this.checkfriend.length - i ",this.checkfriend.length - i );
      //   console.log("เช็ค",this.checkloopclosedialog);

      // }

      //อันเก่า
      // if (this.choosefriend !== "") {
      //   let payload = {
      //     account_id: this.dataAccountId,
      //     receiver_id: this.listfriend[this.choosefriend]["one_id"],
      //     file_id: this.filedata.file_id
      //   };
      //   this.createprogress = true;
      //   this.axios
      //     .post(process.env.VUE_APP_SERVICE_ONECHAT + "/api/sendchat", payload)
      //     .then(response => {
      //       if (response.data.status === "OK") {
      //         this.createprogress = false;
      //         Toast.fire({
      //           icon: "success",
      //           title: this.$t('sentFileOnechat.sendfile')
      //         });
      //         this.$emit("closesendonechat");
      //         this.$emit("updatedatafile");
      //         this.cleardata();
      //       } else {
      //         this.createprogress = false;
      //         Toast.fire({
      //           icon: "error",
      //           title: response.data.errorMessage
      //         });
      //       }
      //     })
      //     .catch(error => {
      //       console.log(error);
      //       Toast.fire({
      //         icon: "error",
      //         title: this.$t('sentFileOnechat.cannotsendfile')
      //       });
      //       this.createprogress = false;
      //     });
      // } else {
      //   Toast.fire({
      //     icon: "error",
      //     title: this.$t('sentFileOnechat.addreceiver')
      //   });
      // }
    },
    // async fn_senonechat(payload,one_id,item){
    // ไม่ใช่
    //   console.log(one_id);
    //   let result = await this.axios({
    //     method: "POST",
    //     url: process.env.VUE_APP_SERVICE_ONECHAT + "/api/sendchat",
    //     data: payload,
    //     headers: {},

    //     // onUploadProgress: (e) => {
    //     //   if ((e.loaded / e.total) * 100 >= 95) {
    //     //     this.showdelete[objIndex]["file_value"] = 95;
    //     //   } else {
    //     //     this.showdelete[objIndex]["file_value"] = parseInt((e.loaded / e.total) * 100);
    //     //   }
    //     // },
    //   });
    //     return new Promise((resolve, reject) => {
    //     try {
    //       if (result.data.status === "OK") {
    //             console.log("checkloopclosedialogก่อนif",this.checkloopclosedialog);
    //           // if(this.checkloopclosedialog === true){
    //           //   this.$emit("closesendonechat");
    //           //   this.$emit("updatedatafile");
    //           //   this.cleardata();
    //           // }
    //         Toast.fire({
    //             icon: "success",
    //             title: this.$t('sentFileOnechat.sendfile') + " " + item.display_name + this.$t('sentFileOnechat.success')
    //           });
    //          setTimeout(() => {
    //               resolve({ status: "sendtoonechat success", msg: "OK", code: "" });
    //                 }, 500);

    //       } else {
    //         Toast.fire({
    //             icon: "error",
    //             title: response.data.errorMessage
    //           });
    //         setTimeout(() => {
    //               resolve({ status: "sendtoonechat denied", msg: ": ", code: "" });
    //                 }, 500);

    //       }
    //     } catch (err) {
    //       setTimeout(() => {
    //                resolve({ status: "sendtoonechat denied", msg: ": " + err, code: "" });
    //                 }, 500);
    //     }
    //   });
    // }
  },
};
</script>
<style></style>
